<template>
    <div class="rules">
        <div class="header-wrapper d-flex justify-content-center align-items-center">
            <div class="container text-center header-container-sm text-light">
                <h1>Regelwerk</h1>
                <p class="lead w-75 mx-auto">
                    Unsere Regeln für Minecraft, Teamspeak und Discord
                </p>
            </div>
        </div>

        <b-container class="container my-5">
            <b-row class="mb-3 mb-md-5">
                <b-col cols="12" md="9">
                    <p>
                        Willkommen auf dem Regelwerk von MC ONE!<br>
                        <br>
                        Um dir ein bestmögliches Spieleerlebnis ohne Hacker und respektloses Verhalten bieten zu können,
                        stellen wir hier einige Regeln auf, an die sich jeder Spieler, der sich auf einen unserer Server verbindet, halten muss.
                        Falls er das nicht tut, muss er mit Konsequenzen wie Bans oder Mutes rechnen.
                        Falls dir ein Spieler auffällt der gegen die Regeln verstößt kannst du ihn mit dem Befehl <kbd>/report &lt;Name&gt;</kbd> reporten.<br>
                        Die wichtigsten Regeln sind anhand von Dingen die <span class="text-success font-weight-bold"><fai :icon="['fas', 'check']"></fai> erlaubt sind</span>
                        und Dingen die <span class="text-danger font-weight-bold"><fai :icon="['fas', 'ban']"></fai> verboten sind</span> zu jedem Paragraph noch einmal verständlich erklärt.
                    </p>
                </b-col>
                <b-col class="text-center mb-5 m-md-0 d-none d-md-block">
                    <div class="gavel-wrapper mx-auto mt-md-5 text-light">
                        <fai :icon="['fas', 'gavel']" class="gavel" size="8x"></fai>
                    </div>
                </b-col>
            </b-row>

            <b-row>
                <b-col cols="12" lg="auto" class="mb-4 m-lg-0">
                    <b-navbar v-b-scrollspy="100" class="rules-nav text-center text-lg-left">
                        <b-nav pills vertical>
                            <b-nav-item v-for="(rule, id) in rules" :key="id" :href="'#'+rule.id">
                                {{ '§'+id+' '+rule.name }}
                            </b-nav-item>
                            <small class="mb-2 text-muted ml-md-3">Letzte Aktualisierung: 01.06.2020</small>
                        </b-nav>
                    </b-navbar>
                </b-col>

                <b-col cols="12" md="auto" lg="8">
                    <div v-for="(rule, id) in rules" :key="id" class="mb-5">
                        <h4 :id="rule.id">
                            {{ '§'+id+' '+rule.name }}
                        </h4>
                        <p v-html="rule.text"></p>

                        <div v-if="(rule.dos && rule.dos.length > 0) || (rule.donts && rule.donts.length > 0)" class="explain-box bg-light rounded border p-3">
                            <div v-if="rule.dos">
                                <span v-for="(allow, id) in rule.dos" :key="id">
                                    <b-row class="text-success">
                                        <b-col cols="auto" class="rule-icon">
                                            <fai :icon="['fas', 'check']"></fai>
                                        </b-col>
                                        <b-col>
                                            {{ allow }}
                                        </b-col>
                                    </b-row>
                                </span>
                            </div>

                            <div v-if="rule.donts">
                                <span v-for="(deny, id) in rule.donts" :key="id">
                                    <b-row class="text-danger">
                                        <b-col cols="auto" class="rule-icon">
                                            <fai :icon="['fas', 'ban']"></fai>
                                        </b-col>
                                        <b-col>
                                            {{ deny }}
                                        </b-col>
                                    </b-row>
                                </span>
                            </div>
                        </div>
                    </div>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                rules: [
                    {
                        id: 'scope',
                        name: 'Geltungsbereich',
                        text: `
Die folgenden Regeln gelten auf allen Servern und Plattformen des MC ONE Minecraftnetzwerks.
Als Spieler oder Nutzer einer dieser Plattformen stimmt der Benutzer den Regeln zu und verpflichtet sich diese einzuhalten.
Bei Verstößen jeglicher Art können und werden Strafen in Form von Ausschlüssen aus dem Spielerchat oder dem gesamten Servernetzwerk verhängt.
Siehe <a href="#punishments">Strafen</a>.<br>

Wir behalten uns vor dieses Regelwerk zu jedem Zeitpunkt neuen Gegebenheiten anzupassen und zu verändern.
Wir bemühen uns diese Änderungen im Rahmen der Vorstellung neuer Funktionen mitzuteilen,
jedoch ist es die Pflicht jedes einzelnen Users sich regelmäßig über Aktualisierungen der Regeln in Kenntnis zu setzen.`,
                        dos: [
                            'Wenn ich auf MC ONE spiele stimme ich zu, dass ich mich an die Regeln halte und mich öfters über Neuigkeiten informiere.'
                        ],
                        donts: [
                            'Ich kann Aktualisierungen des Regelwerks ignorieren.'
                        ]
                    },
                    {
                        id: 'accounts',
                        name: 'Benutzerkonten',
                        text: `
Der Zugang zu allen Plattformen des MC ONE Netzwerks ist kostenlos und benötigt größtenteils nur ein Benutzerkonto bei dem jeweiligen Anbieter
(Der Zugang zum Minecraft-Server setzt einen gekauften Account von minecraft.net vorraus. Für Discord wird ein kostenloser Account auf discord.com benötigt. Für Teamspeak muss eine Identität mit Sicherheitsstufe 23 erstellt werden.).<br>

Eine Strafe (z.B. Banns oder Mutes) gilt für den jeweiligen Besitzer des Accounts (auch wenn die Regelverletzende Tat von einem dritten begangen wurde)
und darf nicht mithilfe von Zweitaccounts umgangen werden.
Ebenso ist es verboten mithilfe von Zweitaccounts leichter an Coins, Kills, Goals, Erfahrungspunkte oder andere Aufbesserungen der eigenen Spielerstatistik zu kommen.
Grundsätzlich darf nicht mit zwei Accounts gleichzeitig eine der zu MC ONE gehörenden Plattformen betreten werden.
Auch das weitergeben der eigenen Account-Daten an Dritte ("Account-Sharing") zum Zweck der Verbesserung der eigenen Statistiken ist nicht erlaubt.<br>

`,
                        dos: [
                            'Ich benutze keine Zweitaccounts, um meine Statistiken aufzubessern.',
                            'Ich gebe meine Accountdaten nicht an andere weiter um meine Statistiken aufzubessern und um zu verhindern, dass mein Account wegen anderen gebannt wird.'
                        ],
                        donts: [
                            'Eine Strafe kann mit einem Zweitaccount umgangen werden.'
                        ]
                    },
                    {
                        id: 'expression',
                        name: 'Umgangs- und Ausdrucksformen',
                        text: `
Jegliches unangemessenes Chatverhalten sowohl in Sprach- als auch in Schriftform ob öffentlich oder privat ist gegenüber allen Beteiligten untersagt.
Dazu zählen insbesondere Beschimpfungen, Beleidigungen, Fäkal- und Vulgärsprache, Drohungen, Rufmord, Verleumdung, Lästerei und alles was als solches aufgefasst werden kann.
Dabei spielt es keine Rolle ob die Äußerungen ernst gemeint sind oder nicht.<br>

Das Verbreiten von privaten Informationen jeglicher Art von sich selbst oder von anderen ist nicht erlaubt.
Radikalistische, rassistische und rechtswidrige Ausdrucksweisen / Symbole in jeglicher Form werden nicht geduldet.
Das Verbeiten von Falschinformationen (insbesondere im Bezug auf das Netzwerk oder bestimmter Personen) ist verboten.
Das wiederholte senden des gleichen oder einer ähnlichen Satzes / Wortes / Symbols ist untersagt (Spam).<br>

Werbung für andere Plattformen, Marken, Firmen, verbotene Modifikationen / Accounthändler und Netzwerke
(insbesondere, wenn deren Tätigkeit in einer oder mehreren Teilen der von MC ONE ähnelt, z.B. Spieleserver / Voiceserver / etc.) ist verboten.
Für Social Media Accounts, die nicht diesen Eigenschaften entsprechen und nicht zu einer Instittion gehören,
welche diesen Eigenschaften entspricht ist Werbung erlaubt, falls es sich um den <b>eigenen</b> Account handelt.
Fremdwerbung in jeglicher Form und das schreiben von URLs oder IP-Adressen ist verboten.<br>`,
                        dos: [
                            'Ich verhalte mich höflich, respektiere andere Spieler und benutze kein abfälliges Verhalten. Auch nicht ironisch.',
                            'Ich mache keine Werbung für andere Plattformen, Marken, Firmen, verbotene Modifikationen und Netzwerke.'
                        ],
                        donts: [
                            'Ich mache Werbung für Social Media Accounts von anderen Personen.'
                        ]
                    },
                    {
                        id: 'client',
                        name: 'Client Modifikationen',
                        text: `
Mithilfe von Modifikationen (kurz Mods) des Minecraftclients kann ein unfährer Spielvorteil gegenüber anderen Spielern entstehen.
Deshalb wird die Nutzung auf Mods beschränkt welche nicht mehr als die folgenden Funktionen bieten.
MC ONE ist nicht verantwortlich für eventuelle Probleme, die mit modifizierten Versionen von Minecraft auftreten und kann hier auch keinen Support bieten.
Daher wird für einen Reibungslosen Spielablauf vom Benutzen Clientseitiger Modifikationen abgeraten.<br>
<br>
Erlaubte Funktionen von Mods:
<ul>
    <li>
        Verändern / Verbessern der Grafischen Leistung,
        ohne die Sichtbarkeit und Funktion einzelner Blöcke und Items zu verändern oder andere Vorteile zu verschaffen
        (z.B. Optifine, Shader-/Texturen-/Animationen-Mods)
    </li>
    <li>
        Veränderung des Chats
    </li>
    <li>
        Minimaps ohne Spieler oder andere Entities anzuzeigen
    </li>
    <li>
        Veränderungen, die ausschließlich im Einzelspielermodus gelten
    </li>
    <li>
        Automatisches Sprinten / Sneaken (sofern keine Abweichung zu Vanilla-Minecraft beim öffnen von Kisten oder dem Inventar besteht)
    </li>
</ul>`,
                        dos: [
                            'Ich benutze nur Mods wie Optifine, LabyMod (ab Version 3), Badliend Client (ab Version 2), Replay-Mod, Worldedit Mod.',
                            'Ich deaktiviere verbotene Mods bevor ich auf MC ONE spiele'
                        ],
                        donts: [
                            'Ich kann Plugins für Labymod oder Badlion Client nutzen, welche gegen die Regeln verstoßen.'
                        ]
                    },
                    {
                        id: 'server',
                        name: 'Verhalten auf den Plattformen',
                        text: `
Auf allen Plattformen von MC ONE (Minecraft-, Teamspeak- und Discord-Server) gelten folgende Verhaltenformen:<br>

Das Ausnutzen von System- oder Server-Fehlern ("Bugs") ist verboten. Solche Fehler sollten einem Teammitlgied über eine beliebige Plattform gemeldet werden.
Das Tragen eines Benutzernamens oder Profilbildes / Avatars / Skins oder das Vergeben eines Namens an Items im Spiel, dessen Inhalt gegen die in §2 festgelegten Umgangs- und Ausdrucksformen verstößt, ist verboten.
Ebenso ist das Ausgeben als eine bekannte Person oder als Teammitlglied (z.B. durch nachahmen) untersagt.<br>

Jeglicher Verkauf von Accounts, Dienstleistungen oder anderen Produkten, ob substanziell oder immateriell, ist untersagt.
Das Weitergeben von Nicknamen eines Spielers auf dem aktuellen Server an andere Spieler ist verboten.`,
                        dos: [
                            'Ich nutze keine Bugs aus, sondern informiere ein Teammitglied.',
                            'Ich habe kein Benutzernamen / Skin / Avatar / Profilbild, dessen Inhalt gegen §2 verstößt und gebe mich nicht als jemand anderes aus.'
                        ],
                        donts: [
                            'Ich verkaufe gecrackte Minecraftaccounts an Spieler, die ich auf MC ONE anschreibe.'
                        ]
                    },
                    {
                        id: 'punishments',
                        name: 'Strafen',
                        text: `
Im Falle eines Verstoßes gegen das Regelwerk wird ein Teammitglied durch einen Report eines anderen Spielers informiert und hinzugezogen.
Falls sich der Report bewahrheitet, erhält der betroffene Spieler durch das Teammitglied mindestens eine der folgenden Strafen für eine bestimmte oder unbegrenzte Zeitspanne:
<ul>
    <li>Ausschluss vom Minecraft Server-Chat</li>
    <li>Ausschluss vom Server der jeweiligen Plattform (Minecraft / Teamspeak / Discord)</li>
    <li>Löschung der Statistiken des Spielers</li>
</ul>
Der betroffene Spieler hat bis Ende der Strafe, oder bei einer permanenten Strafe bis ein Jahr nach dem Regelverstoß, Zeit ein <a href="https://id.onegaming.group/tickets/new?cat=unban">Entbannungsantrag</a> in Form einer Gegendarstellung abzuschicken,
falls der Bestrafungsgrund unberechtigt oder unangemessen ist. Bei angemessener Beweisführung kann die Strafe, durch ein Teammitglied gemildert oder aufgehoben werden.`,
                        dos: [
                            'Falls der Banngrund für meine Strafe nicht der Wahrheit entspricht kann ich einen Entbannungsantrag abschicken. ' +
                            'Das muss ich innerhalb meiner Bannzeit oder bei einem Permabann innerhalb des ersten Jahres nach dem Bann tun.'
                        ],
                        donts: [
                            'Mithilfe eines Entbannungsantrags werde ich auf jeden Fall entbannt.'
                        ]
                    }
                ]
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import "~bootstrap/scss/functions";
    @import "~bootstrap/scss/variables";
    @import "~bootstrap/scss/mixins/breakpoints";

    .header-wrapper {
        background: linear-gradient(
                        to right,
                        rgba(0, 0, 0, 0.8),
                        rgba(55, 55, 55, 0.75)
        );
        background-size: cover;
        padding-top: 50px;
    }

    .header-container-sm {
        padding: 75px 0;
    }

    .rules-nav {
        margin-left: -18px;
        flex-direction: column;
        padding: 0;
        position: sticky;
        top: calc(72px + 20px)
    }

    .explain-box {

    }

    .gavel-wrapper {
        width: 120px;
        height: 120px;
        border-radius: 50%;
        filter: drop-shadow(0 0 15px rgba(0, 0, 0, .4));

        background: linear-gradient(
            to bottom right,
            rgba(255, 0, 0, 0.32),
            rgba(255, 165, 0, 0.31)
        );
    }

    .gavel {
        position: absolute;
        transform: translate(calc(-50% + 5px), -5px);
    }

    .rule-icon {
        width: 20px;
    }

    .nav-pills {
        width: 100%;
    }
</style>
